import React from "react";
import { highlightPlugin, Trigger } from "@react-pdf-viewer/highlight";

export const highlightUtil = (highlightAreas, currentFile = null) => {
  // https://react-pdf-viewer.dev/examples/render-the-highlight-areas/
  // console.log('-currentFile-', currentFile)
  // console.log('-highlightAreas-', highlightAreas)
  const renderHighlights = (props) => {
    return (
      <div>
        {highlightAreas
          .filter(({ pageIndex }) => pageIndex === props.pageIndex)
          .filter(
            ({ file_id: fileID }) => 
              currentFile === null ||
              (typeof currentFile === "string" &&
                (currentFile.endsWith(`${fileID}.pdf`) || currentFile.endsWith(fileID))))
          .map((area, idx) => (
            <div
              key={idx}
              className="highlight-area"
              style={{
                background: "yellow",
                opacity: 0.4,
                ...props.getCssProperties(area, props.rotation),
              }}
            />
          ))}
      </div>
    )
  };

  return highlightPlugin({
    trigger: Trigger.None,
    renderHighlights,
  });
};


export const consistencyCheckHighlightUtil = (
  highlightAreas,
  currentFile = null,
  trigger = Trigger.TextSelection,
  type = 1,
) => {
  // https://react-pdf-viewer.dev/examples/render-the-highlight-areas/
  // console.log('-currentFile-', currentFile)
  // console.log('-highlightAreas-', highlightAreas)
  const renderHighlights = (props) => (
    <div>
      {highlightAreas[0]
        .filter(({ pageIndex }) => pageIndex === props.pageIndex)
        .filter(
          ({ file_id: fileID }) => 
            currentFile === null ||
            (typeof currentFile === "string" &&
              (currentFile.endsWith(`${fileID}.pdf`) || currentFile.endsWith(fileID))))
        .map((area, idx) => {
          return (
            <div
              key={idx}
              className="highlight-area"
              style={{
                background: area.active ? "yellow" : "",
                opacity: 0.4,
                ...props.getCssProperties(area, props.rotation),
              }}
            />
          )
        })}
    </div>
  );

  const renderHighlightTarget = ({
    selectedText,
    selectionRegion,
    ...props
  }) => {
    // console.log('-selectionRegion-', selectionRegion)
    // console.log('-props-', props)
    if (selectedText && Array.isArray(highlightAreas[0]) && Array.isArray(highlightAreas[1])) {

      const matchIndex = highlightAreas[0].findIndex(area => 
        (selectedText && area.value.includes(selectedText) && area.pageIndex === selectionRegion.pageIndex)
      )
      console.log('matchIndex', matchIndex)
      if (matchIndex === -1) return (<></>)
      return (
        <div
          style={{
            background: '#fff',
            position: 'absolute',
            left: `${selectionRegion.left-10}%`,
            top: `${selectionRegion.top + selectionRegion.height-1}%`,
            transform: 'translate(0, 8px)',
            padding: 10,
            zIndex: 999,
            boxShadow: "1px 1px 8px #9E9E9E",
            borderRadius: 6,
            fontSize: 13,
            "-webkit-user-select": "none",
            "-ms-user-select": "none",
            "user-select": "none"
          }}
        >
          { type == 2 ? (
            <>
              <div>Curr year: <b>{ highlightAreas[0][matchIndex].value.replace(/[()$]/gi, "") }</b></div>
              <div>Prev year: <b>{ highlightAreas[1][matchIndex].value.replace(/[()$]/gi, "") }</b></div>
            </>
          ) : (
            <>
              <div>Curr year: <b>{ highlightAreas[1][matchIndex].value.replace(/[()$]/gi, "") }</b></div>
              <div>Prev year: <b>{ highlightAreas[0][matchIndex].value.replace(/[()$]/gi, "") }</b></div>
            </>
          )}
        </div>
      )
    }
    else {
      return (<></>)
    }
  }

  return highlightPlugin({
    trigger,
    renderHighlights,
    renderHighlightTarget: trigger === Trigger.TextSelection ? renderHighlightTarget : undefined,
  });
};
