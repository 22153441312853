import React, { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { useSelector } from "react-redux";
import { customizationsSelector } from "./redux/reducers/app.reducer";
import AppsList from "./appsPage/AppsList";
import useAppList from "./appsPage/useAppList";

const Home = () => {
  // const links = [
  //   {
  //     title: "Invoice Cross Reference",
  //     description:
  //       "Reconcile invoices with a bank statement. Download annotated original files.",
  //     to: "/invoice_cross_reference",
  //   }
  // ];

  // const faqs = [
  //   {
  //     question: "What is Tellen?",
  //     answer:
  //       "Tellen is a specialized chatbot for finance professionals, trained in tax and accounting standards, including IFRS, US GAAP, tax regulations, and auditing procedures like ISA and PCAOB standards.",
  //   },
  //   {
  //     question: "How does Tellen benefit finance professionals?",
  //     answer:
  //       "Tellen enhances productivity by providing quick information to natural language queries and serving as a continuous learning resource. Tellen also provides embedded links to further resources for in-depth exploration of topics.",
  //   },
  //   {
  //     question:
  //       "How does Tellen differ from ChatGPT, Claude, and other chatbots?",
  //     answer:
  //       "Tellen uses prompt engineering (chain of thought prompting, and routing logic), as well as retrieval-augmented generation, which enable it to provide better and context-aware responses. In the future, Tellen will fine tune Large Language Models (LLMs) with accounting and audit guidance, to give results with a much higher accuracy.",
  //   },
  //   {
  //     question: "What kind of information can I expect from Tellen?",
  //     answer:
  //       "Tellen can provide comprehensive information on accounting standards, auditing procedures, tax regulations, and related topics. It offers detailed explanations, examples, and insights to help you understand and navigate these complex subjects.",
  //   },
  //   {
  //     question: "Is Tellen reliable and up-to-date?",
  //     answer:
  //       "Tellen aims to provide reliable and up-to-date information. However, Large Language Models(LLMs) have risks and limitations in terms of accuracy and timeliness. In the near future, we are planning to train new, specialized LLMs specifically focused on accounting, auditing, and tax guidance. Your valuable feedback and input are essential in this process, as they help us identify areas that require improvement and refinement. We appreciate your partnership as we work towards delivering even more reliable and current guidance through Tellen.",
  //   },
  // ];

  const { name: companyName } = useSelector(
    customizationsSelector
  );

  const { isSignedIn, user, isLoaded } = useUser();

  const [firstNameText, setFirstNameText] = useState("");

  const appData = useAppList();

  useEffect(() => {
    if (isLoaded && isSignedIn && user?.firstName?.length > 0) {
      let name = "";
      if (user["firstName"].includes(",")) {
        name = user["lastName"]
      } else {
        name = user["firstName"]
      }
      setFirstNameText(`, ${name}`);
    }
  }, [isLoaded, isSignedIn, user]);

  return (
    <div className="h-screen flex">
      <div className="flex flex-col">
        <div className="flex">
          <div className="w-2/3 flex flex-col">
            <h1 className="text-4xl font-bold mb-5">
              Welcome to {companyName || "Tellen"} AI
              {firstNameText ? `${firstNameText}!` : "!"}
            </h1>
            {/* <SignedIn>
                <Link to="/invoice_cross_reference">
                  <button
                    // type="submit"
                    className="transition-colors duration-300 bg-customBlack hover:bg-customHighlightColor text-customLightGray hover:text-customLightGray font-bold p-3 rounded"
                  >
                    Try the Invoice Cross Reference tool
                  </button>
                </Link>
              </SignedIn> */}
            {/* <SignedOut>
                <Link to="/sign_in">
                  <button
                    // type="submit"
                    className="transition-colors duration-300 bg-customBlack hover:bg-customHighlightColor text-customLightGray hover:text-customLightGray font-bold p-3 rounded"
                  >
                    Sign up to get started
                  </button>
                </Link>
              </SignedOut> */}
          </div>
        </div>

        <AppsList pageName="My Apps" appsArray={appData && appData.my_apps} />
      </div>

      {/* Right Panel (1/3 width) */}
      {/* <div className="w-1/3 flex-grow p-2">
        {faqs.length > 0 && <h1 className="text-2xl font-bold mb-5">FAQs</h1>}
        {faqs.map((faq, index) => (
          <AccordionItem
            key={index}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </div> */}
    </div>
  );
};

export default Home;
