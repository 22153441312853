import { ScrollSyncNode } from 'scroll-sync-react';

const customSyncPlugin = () => {
  return {
    renderViewer: (renderProps) => {
      const { slot } = renderProps;
      const mergeSubSlot = slot.subSlot?.attrs
        ? {
            className: slot.subSlot.attrs.className,
            'data-testid': slot.subSlot.attrs['data-testid'],
            ref: slot.subSlot.attrs.ref,
            style: slot.subSlot.attrs.style,
          }
        : {};

      slot.children = (
        <ScrollSyncNode>
          <div {...mergeSubSlot}>
            {slot.children}
            {slot.subSlot?.children}
          </div>
        </ScrollSyncNode>
      );

      // Reset the sub slot
      if (slot.subSlot) {
        slot.subSlot.attrs = {};
        slot.subSlot.children = <></>;
      }

      return slot;
    },
  };
};

export default customSyncPlugin;
