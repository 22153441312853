import React, { useState, useEffect } from "react";
import { ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";
import TableDetails from "../../utils/TableDetails";
import { getFileDownloadUrl, getPDFDownloadURL } from "../../utils/utils";

const shouldHighlight = (selectedFootnote, paragraphIndex) => {
  console.group('Highlight Check');
  
  console.log('Footnote:', selectedFootnote);
  console.log('Paragraph Index:', paragraphIndex);

  if (!selectedFootnote) {
    console.log('Return false: no footnote');
    console.groupEnd();
    return false;
  }

  const topParagraphs = selectedFootnote.top_paragraphs;
  console.log('Top Paragraphs:', topParagraphs);
  
  if (!topParagraphs) {
    console.log('Return false: no top paragraphs');
    console.groupEnd();
    return false;
  }

  if (Array.isArray(topParagraphs)) {
    const shouldHighlightResult = topParagraphs.includes(paragraphIndex);
    console.log('Array case - Should highlight:', shouldHighlightResult);
    console.groupEnd();
    return shouldHighlightResult;
  }

  const matchingKey = Object.keys(topParagraphs).find(key =>
    parseInt(key) === selectedFootnote.footnote_id
  );
  console.log('Matching Key:', matchingKey);

  if (!matchingKey) {
    console.log('Return false: no matching footnote ID');
    console.groupEnd();
    return false;
  }

  // Check if the paragraphIndex matches the value for this key
  const shouldHighlightResult = topParagraphs[matchingKey].includes(paragraphIndex);
  console.log('Should highlight:', shouldHighlightResult);
  
  console.groupEnd();
  return shouldHighlightResult;
};

const FootnotesList = ({ footnotes, isPublic, topParagraphs }) => {
  const [selectedFootnote, setSelectedFootnote] = useState(null);

  // Auto-select first footnote when footnotes change
  useEffect(() => {
    if (footnotes && footnotes.length > 0) {
      setSelectedFootnote(footnotes[0]);
    } else {
      setSelectedFootnote(null);
    }
  }, [footnotes]);

  console.log("Footnotes: ", footnotes);
  console.log("TOP PARAGRAP: ", topParagraphs);

  const handleFootnoteClick = (footnote) => {
    setSelectedFootnote(footnote);
  };

  const handleCloseDetailView = () => {
    setSelectedFootnote(null);
  };

  return (
    <div className="flex h-screen gap-4">
      {/* Left Column - List */}
      <div className="w-1/2 overflow-y-auto border-r border-gray-300 pb-4 px-4">
        {footnotes.length === 0 && (
          <div className="flex items-center justify-center h-full">
            <div className="text-center text-gray-500">
              <p className="text-xl font-semibold mb-2">No Results Found</p>
              <p className="text-sm">Try adjusting your search criteria or filters</p>
            </div>
          </div>
        )}
        {footnotes && footnotes.map((footnote) => (
          <div
            key={footnote.id}
            onClick={() => handleFootnoteClick(footnote)}
            className={`cursor-pointer p-4 mb-4 bg-white   rounded-lg  transition-all ${
              selectedFootnote && selectedFootnote.id === footnote.id
                ? "border-2 border-customHighlightColor"
                : "border-2 border-gray-300"
            }`}
          >
            <h2 className="text-xl font-semibold text-purple-900">
              {footnote.footnote.title}
            </h2>
            <p className="text-sm font-bold text-purple-700">
              {footnote.date?.replace("00:00:00 GMT", "")}
            </p>
            <p className="text-sm text-gray-600">
              {footnote.footnote?.paragraphs[1]?.text.slice(0, 200) + "..."}
            </p>
            <p className="text-sm font-bold text-purple-700">
              {footnote.company_name}
            </p>
            <p className="text-sm text-gray-700">
              {selectedFootnote?.industry_name}
            </p>
          </div>
        ))}
      </div>

      {/* Right Column - Detail View */}
      <div className="w-1/2 overflow-y-auto flex flex-col h-full bg-white rounded-lg shadow-lg border-2 border-customHighlightColor relative">
        <div className="sticky bg-white top-0 right-0">
          <button
            onClick={handleCloseDetailView}
            className="absolute z-10 top-3 right-3 text-gray-400 hover:text-gray-600 bg-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        {selectedFootnote ? (
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <div className="flex items-center space-x-2">
              <a
                className="text-gray-600 hover:text-customHighlightColor font-bold no-underline text-sm flex items-center"
                href={getPDFDownloadURL(selectedFootnote.pdf_file_id, isPublic)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ArrowDownOnSquareIcon
                  className="h-6 w-6 shrink-0 mr-1"
                  aria-hidden="true"
                />
                Download 10K
              </a>
            </div>
            <br />
            <br />
            <p className="text-sm font-bold text-purple-700">
              {selectedFootnote.company_name}
            </p>
            <p className="text-sm text-gray-700">
              {selectedFootnote?.industry_name}
            </p>
            <h1 className="text-3xl font-bold text-purple-900 mb-4">
              {selectedFootnote?.footnote.title}
            </h1>
            <p className="text-sm font-bold text-purple-700">
              {selectedFootnote?.date?.replace("00:00:00 GMT", "")}
            </p>
            <div className="text-sm text-gray-700 space-y-2 mb-4">
              {selectedFootnote.footnote?.paragraphs.map(
                (p, index) =>
                  index > 0 && (
                    <p
                      key={index}
                      className={
                        shouldHighlight(selectedFootnote, index)
                          ? "bg-yellow-200 border border-yellow-500"
                          : ""
                      }
                    >
                      {p.text}
                    </p>
                  )
              )}
            </div>
            <div className="text-sm text-gray-700 space-y-2 mb-4">
              {selectedFootnote.footnote?.tables?.map((p, index) => {
                return (
                  <div key={index} className="overflow-x-auto">
                    <TableDetails
                      tableDetails={p.cells}
                      fileID={"N/A"}
                      setHighlightAreas={() => {}}
                      jumpToPage={() => {}}
                    />
                  </div>
                );
              })}
            </div>
            <div className="mt-4">
              <img
                src={
                  getFileDownloadUrl(
                    selectedFootnote.screenshot_file_id,
                    undefined,
                    isPublic ? "https://tellencl.blob.core.windows.net/10kdatabase" : undefined
                  )
                }
                alt="Footnote Screenshot"
                className="w-full h-auto object-contain rounded-md"
              />
            </div>
          </div>
        ) : (
          <div className="h-full flex items-center justify-center">
            <p className="text-gray-500">Click a footnote to see details</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FootnotesList;