import React from "react";
import { Viewer as PDFViewer } from "@react-pdf-viewer/core";
import { includes } from "lodash"
import {
  getBaseURL,
  getFileExt,
  imgExtConst,
  pdfExtConst,
  docxExtConst,
  xlsxExtConst,
} from "../utils";
import DocxViewer from "./DocxViewer";
import ExcelViewer from "./ExcelViewer";

// Reminder that we can use this too:
// onPageChange={({ currentPage }) =>
//   setCurrentPDFPage(currentPage + 1)
// }

/**
 * A React component for displaying files based on their extension. It supports both image and PDF files.
 * For image files, it renders an `<img>` tag. For PDF files, it uses the `@react-pdf-viewer/core` library to render a PDF viewer.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.fileUrl - The URL of the file to be displayed.
 * @param {...rest} props.rest - Any additional props that should be passed to the underlying `<img>` or `<PDFViewer>` components.
 *
 * @example
 * // Usage in a component
 * <Viewer fileUrl="/path/to/file.jpg" alt="Description" />
 *
 * @note The `maxHeight` and `height` styles are explicitly defined for the PDF viewer container. This is crucial for the PDF viewer to render correctly, as it relies on these styles for its layout.
 * @note when modifying this component, always make sure to have `height` defined
 */
const Viewer = ({
  fileUrl,
  styles,
  ...rest
}) => {
  const maxHeight = "750px";
  const fullURL = `${getBaseURL()}${fileUrl}`;
  const fileExt = getFileExt(fileUrl);
  if (!styles) styles = { maxHeight, height: maxHeight }
  return (
    <>
      {includes(imgExtConst, fileExt) && (
        <img src={fullURL} {...rest} />
      )}
      {includes(pdfExtConst, fileExt) && (
        <div style={styles}>
          <PDFViewer fileUrl={fullURL} {...rest} />
        </div>
      )}
      {includes(docxExtConst, fileExt) && (
        <div style={styles}>
          <DocxViewer docUrl={fullURL} />
        </div>
      )}
      {includes(xlsxExtConst, fileExt) && (
        <div style={styles}>
          <ExcelViewer fileUrl={fullURL} />
        </div>
      )}
    </>
  );
};

export default Viewer
