import React, { useEffect, useRef } from "react";
import TypingDots from "../utils/TypingDots";

/**
 * `BlackComputerScreen` is a React component that simulates the appearance of a computer screen with scrolling text lines.
 * It displays a list of text lines against a black background, mimicking the look of a terminal or command line interface.
 * The component is designed to handle dynamic updates to the text lines, automatically scrolling to show the latest entry
 * unless the user has manually scrolled up. It also supports a typing animation for lines ending in '...'.
 *
 * Props:
 *  - lines (Array of strings): The lines of text to display on the screen. Each element in the array represents a single line.
 *
 * Behavior:
 *  - Displays up to 5 lines at a time, with additional lines accessible via vertical scrolling.
 *  - Automatically scrolls to the bottom when new lines are added, unless the user has scrolled up.
 *  - Applies a typing animation to lines ending with '...'.
 *
 * Styling:
 *  - Utilizes Tailwind CSS for styling. The screen has a black background, white text, rounded corners, and a monospace font.
 *  - Text lines are designed to wrap if they are too long to fit the width of the screen, ensuring readability.
 *
 * Example:
 * ```jsx
 * <BlackComputerScreen lines={['Initializing...', 'Loading modules...', 'System ready.']} />
 * ```
 *
 * Note:
 *  - This component uses React hooks such as `useRef` and `useEffect` for managing scroll behavior and detecting updates to the lines.
 */

const BlackComputerScreen = (props) => {
  const lineHeight = 20; // Height of each line (in pixels)
  const maxLines = 1; // Max number of lines to display at once
  const divRef = useRef(null); // Ref to the scrollable div
  const prevLineCount = useRef(props.lines.length); // Ref to store the previous line count
  const userHasScrolledUp = useRef(false); // Track if the user has scrolled up

  const handleScroll = () => {
    const div = divRef.current;
    // User has scrolled up if they're not at the bottom
    userHasScrolledUp.current =
      div.scrollTop + div.clientHeight < div.scrollHeight;
  };

  useEffect(() => {
    const div = divRef.current;
    if (div) {
      if (
        !userHasScrolledUp.current &&
        props.lines.length > prevLineCount.current
      ) {
        // Auto-scroll to bottom only if new lines are added and user hasn't scrolled up
        div.scrollTop = div.scrollHeight - div.clientHeight;
      }
    }

    prevLineCount.current = props.lines.length;
  }, [props.lines]); // Depend on props.lines to trigger on new lines

  return (
    <div
      ref={divRef}
      className="bg-black text-white font-mono text-sm p-4 rounded overflow-auto"
      style={{ height: `${lineHeight * maxLines + 100}px` }}
      onScroll={handleScroll}
    >
      {props.lines.map((line, index) => (
        <div
          key={index}
          className="break-words" // Tailwind CSS class for breaking words to prevent overflow
          style={{ height: "auto", whiteSpace: "normal" }} // Remove fixed height and set whiteSpace to normal
        >
          {line.endsWith("...") ? (
            <>
              {line.slice(0, -3)}
              <TypingDots />
            </>
          ) : (
            <>{line}.</>
          )}
        </div>
      ))}
    </div>
  );
};

export default BlackComputerScreen;
