import React from "react";
import FileUpload from "./utils/FileUpload/FileUpload";
import SourcesTable from "./utils/SourcesTable";
import Typed from "react-typed";

const DynamicForm = ({
  currentPDFURL,
  dynamicFields,
  dynamicValues,
  fileIDs,
  filesDropped,
  fileType = "",
  handleURLSubmit,
  isParsing,
  isUploading,
  onDelete = () => {},
  onDrop,
  onInputChange,
  setCurrentPDFURL,
  setSuccessfulFileUploads,
  showDelete = false,
  successfulFileUploads,
  urlInput,
  urlInputSubmitPressed,
  progressFiles,
  showSourcesTable = true,
}) => {
  return (
    <>
      {Object.entries(dynamicFields).map(
        ([key, { field_id, field_name, description, type }]) => (
          <div key={field_id} className="flex flex-col gap-2 mb-4">
            <label
              htmlFor={field_id}
              data-testid="dynamic-label"
              className="block text-gray-700 font-bold mb-1"
            >
              {field_name}:
            </label>
            {type === "textarea" && (
              <textarea
                id={field_id}
                data-testid="dynamic-textarea"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder={description}
                value={dynamicValues[field_id]}
                onChange={onInputChange}
              ></textarea>
            )}
            {(type === "text" || type === "email") && (
              <input
                type={type}
                id={field_id}
                data-testid="dynamic-input"
                className="shadow appearance-none border rounded w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder={description}
                value={dynamicValues[field_id]}
                onChange={onInputChange}
              />
            )}
            {type === "file_upload" && (
              <>
                <FileUpload
                  onDrop={onDrop}
                  testID="dynamic-file-upload"
                  urlInput={urlInput}
                  hideURLInput={false}
                  handleURLSubmit={handleURLSubmit}
                  uploadedFiles={progressFiles}
                />
                <div className="mt-2">
                  {(urlInputSubmitPressed ||
                    filesDropped ||
                    successfulFileUploads.length > 0) && (
                    <>
                      <p>
                        {showSourcesTable && (
                          <SourcesTable
                            currentPDFURL={currentPDFURL}
                            onDelete={onDelete}
                            fileType={fileType}
                            setCurrentPDFURL={setCurrentPDFURL}
                            setSuccessfulFileUploads={setSuccessfulFileUploads}
                            showDelete={showDelete}
                            successfulFileUploads={successfulFileUploads}
                            urlInput={urlInput}
                            urlInputSubmitPressed={urlInputSubmitPressed}
                          />
                        )}

                        {/* {
                                <button
                                  className="text-gray-500 text-sm hover:text-customHighlightColor bg-white"
                                  onClick={() => setAddMoreFilesModalOpen(true)}
                                >
                                  + Add more{" "}
                                  {fileType != "Invoice" ? "files" : "invoices"}
                                </button>
                              } */}
                      </p>
                      {isUploading && (
                        <p className="text-sm">
                          Uploading{" "}
                          <Typed strings={["..."]} loop typeSpeed={40} />
                        </p>
                      )}
                      {isParsing && (
                        <p className="text-sm">
                          Parsing source{fileIDs.length > 1 ? "s" : null}
                          <Typed strings={["..."]} loop typeSpeed={40} />
                        </p>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        )
      )}
    </>
  );
};

export default DynamicForm;
