import React, { useRef } from "react";
import cn from "classnames"
import { Tooltip } from "react-tooltip"
import { SpecialZoomLevel } from '@react-pdf-viewer/core';
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import {
  CheckCircleIcon,
  XCircleIcon,
  ArrowPathRoundedSquareIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
} from "@heroicons/react/24/outline";
import { Viewer } from "../../utils";

const ConsistencyCheckViewers = ({
  plugins,
  activePDFs,
  isComparing,
  isAnnotating,
  checkOptions,
  processedDates,
  checkOptionKey,
  annotatedPdfData,
  highlightViewers,
  sortedFileEntries,
  selectedItemIndex,
  isScrollDisabled,
  setIsScrollDisabled,
  isFullScreen,
  setIsFullScreen,
}) => {

  const refViewer1 = useRef(null)
  const refViewer2 = useRef(null)
  const rightPanelRef = useRef(null)

  const [viewerHeight, setViewerHeight] = React.useState()

  const toolbarPluginInstance1 = toolbarPlugin();
  const { Toolbar: Toolbar1 } = toolbarPluginInstance1;
  const toolbarPluginInstance2 = toolbarPlugin();
  const { Toolbar: Toolbar2 } = toolbarPluginInstance2;

  React.useEffect(() => {
    if (rightPanelRef.current?.offsetHeight) {
      setViewerHeight(rightPanelRef.current.offsetHeight - 88)
    }
  }, [rightPanelRef])
  
  return (
    <>
      {sortedFileEntries && sortedFileEntries?.length > 0 ? (
        <>
          <div className="flex flex-1 h-full overflow-y-scroll gap-1">
            {sortedFileEntries.map(([fileArrayString, arrayValues], index) => {
              const Toolbar = index === 0 ? Toolbar1 : Toolbar2
              if (index === 1 && checkOptions.mathAccuracy) {
                return null;
              }
              return (
                !checkOptions.mathAccuracy || (checkOptions.mathAccuracy && index === 0)
              ) && (
                <React.Fragment key={fileArrayString}>
                  <div key={fileArrayString} className="flex-1 border rounded-t">
                    <div className="border-b p-2">
                      <div className="text-center font-medium">
                        {index === 0
                          ? `Current Year: ${processedDates.currentYear}`
                          : `Previous Year: ${processedDates.previousYear}`}
                      </div>
                    </div>
                    <Toolbar />
                    {isAnnotating || isComparing ? (
                      <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50">
                        <div className="text-gray-600">{ isComparing ? "Comparing PDF..." : "Creating annotated PDF..." }</div>
                      </div>
                    ) : (
                      <>
                        <div className="overflow-y-scroll" ref={index === 0 ? refViewer1 : refViewer2}>
                          <Viewer
                            fileUrl={`/api/files/${index === 0 ? activePDFs.file1 : activePDFs.file2}`}
                            plugins={[
                              ...plugins[checkOptions.mathAccuracy ? 1 : index],
                              index === 0 ? toolbarPluginInstance1 : toolbarPluginInstance2,
                            ]}
                            styles={{
                              height: (isFullScreen && viewerHeight) ? viewerHeight : 519,
                              maxHeight: (isFullScreen && viewerHeight) ? viewerHeight : 519
                            }}
                            defaultScale={SpecialZoomLevel.PageWidth}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </React.Fragment>
              )
            })}
          </div>
          <div ref={rightPanelRef} className="lg:w-[17rem] sm:w-[17rem] bg-white border-l border-t border-b rounded-l-md px-2">
            <div className="flex flex-row justify-between align-center text-customBlack border-b px-1 py-2">
              { checkOptions.previousYear && "Previous Year Check" }
              { checkOptions.trackedChanges && "Differences" }
              { checkOptions.mathAccuracy && "Summation" }

              <div className="flex flex-row gap-1">
                { !checkOptions.mathAccuracy && (
                  <div
                    className="cursor-pointer"
                    data-tooltip-content={
                      `${isScrollDisabled ? "Start" : "Stop"} scrolling two PDF docs at the same time?`
                    }
                    onClick={() => setIsScrollDisabled(!isScrollDisabled)}
                  >
                    <ArrowPathRoundedSquareIcon
                      className={cn(
                        "w-5 h-5",
                        {
                          "text-gray-400": isScrollDisabled,
                          "text-customHighlightColor": !isScrollDisabled
                        }
                      )}
                    />
                  </div>
                )}

                <div
                  className="cursor-pointer"
                  data-tooltip-content={
                    `${isFullScreen ? "Close" : "Open"} full screen`
                  }
                  onClick={() => setIsFullScreen(!isFullScreen)}
                >
                  { isFullScreen ? (
                    <ArrowsPointingInIcon className="w-5 h-5" />
                  ) : (
                    <ArrowsPointingOutIcon className="w-5 h-5" />
                  ) }
                </div>
              </div>
            </div>
            <div className="overflow-y-scroll h-[calc(100%-5rem)]">
              { checkOptionKey &&
                annotatedPdfData.file1?.[checkOptionKey]?.map((py, pyIx) => (
                  <div
                    key={pyIx}
                    className={cn(
                      "p-2 rounded border-1 my-1 cursor-pointer",
                      { "border-customHighlightColor shadow-sm": selectedItemIndex === pyIx }
                    )}
                    onClick={() => highlightViewers(checkOptionKey, pyIx)}
                  >
                    { checkOptionKey === "previousYear" || checkOptionKey === "trackedChanges" ? (
                      <>
                        { py.is_equal ? (
                          <CheckCircleIcon className="h-6 w-6 text-green-500 mb-1" />
                        ) : (
                          <XCircleIcon className="h-6 w-6 text-red-500 mb-1" />
                        )}
                        <div className="text-sm">
                          <div className="relative">
                            Previous year:
                            <span className="text-customBlack text-base ml-1">
                              { py.value }
                            </span>
                            <span
                              className="ml-1 absolute top-1 text-white bg-customHighlightColor rounded-sm px-0.5 text-xs"
                              title={`Page ${py.page_number - 1}`}
                            >
                              {py.page_number - 1}
                            </span>
                          </div>
                          { annotatedPdfData.file2?.[checkOptionKey]?.[pyIx].value && (
                            <div className="relative">
                              Current year:
                              <span className="text-customBlack text-base ml-1">
                                { annotatedPdfData.file2[checkOptionKey][pyIx].value }
                              </span>
                              <span
                                className="ml-1 absolute top-1 text-white bg-customHighlightColor rounded-sm px-0.5 text-xs"
                                title={`Page ${annotatedPdfData.file2[checkOptionKey][pyIx].page_number - 1}`}
                              >
                                {annotatedPdfData.file2[checkOptionKey][pyIx].page_number - 1}
                              </span>
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="text-sm">
                        <div className="relative">
                          <span className="text-customBlack text-base ml-1">{ py.value }</span>
                          <span
                            className="ml-1 absolute top-1 text-white bg-customHighlightColor rounded-sm px-0.5 text-xs"
                            title={`Page ${py.page_number - 1}`}
                          >
                            {py.page_number - 1}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
              )) }
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default ConsistencyCheckViewers